<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Ladies Type</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="ladiesInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { apiGet, apiPost } from "../../services/api";

export default {
  name: "FormLadiesType",
  data() {
    return {
      name: "Form Ladies Type",
      form: {
        name: "",
      },
      errors: {},
      isSubmitting: false,
      edit: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("ladies_type/" + id).then((result) => {
        this.form = result.data.data;
        this.edit = true;
      });
    },
    ladiesInsert() {
      this.isSubmitting = true;
      if (this.edit == false) {
        apiPost("ladies_type/store", this.form)
          .then(() => {
            this.$noty.success("Your Ladies Type has been saved!");
            this.$router.push({ name: "LadiesType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        let id = this.$route.params.id;
        apiPost("ladies_type/update/" + id, this.form)
          .then(() => {
            this.$noty.success("Your Ladies Type has been saved!");
            this.$router.push({ name: "LadiesType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
